import React, { useRef } from 'react';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import styles from 'styles/organisms/QuestionsTable.scss';
import { Question } from '../../../types/Question.js';
import { Topic } from '../../../types/Topic.js';
import { QuestionsTableRow } from '../molecules/QuestionsTableRow.js';

const QuestionsTable = ({
    questions,
    loading,
    topics,
}: {
    questions: Question[];
    loading: boolean;
    topics: Topic[];
}) => {
    const tableRowsReference = useRef();

    useGSAP(
        () => {
            gsap.timeline({
                defaults: {
                    ease: 'power2.out',
                },
            })
                .set('.new-row', {
                    backgroundColor: 'transparent',
                })
                .to('.new-row', {
                    backgroundColor: '#E9EAFF',
                    duration: 1.5,
                    delay: 0.3,
                })
                .to('.new-row', {
                    backgroundColor: 'transparent',
                    duration: 1,
                    delay: 2,
                });
        },
        { scope: tableRowsReference.current, dependencies: [loading, questions.length] },
    );

    const sortedQuestions = questions?.sort((a, b) => {
        return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
    });

    return (
        <Card className={styles.container}>
            <TableContainer>
                <Table className={styles.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.table_header}>
                                Question
                            </TableCell>
                            <TableCell className={styles.table_header}>
                                <div className={styles.topic_cell}>Topic</div>
                            </TableCell>
                            <TableCell className={styles.table_header}>
                                Last Modified
                            </TableCell>
                            <TableCell align="center" className={styles.table_header}>
                                Launch
                            </TableCell>
                            <TableCell align="left" className={styles.table_header} />
                        </TableRow>
                    </TableHead>
                    <TableBody ref={tableRowsReference}>
                        {!loading &&
                            sortedQuestions.map((question) => {
                                const topic = topics.find(
                                    (t) => t.id === question.topic_id,
                                );

                                return (
                                    <QuestionsTableRow
                                        key={question.id}
                                        question={question}
                                        topic={topic}
                                    />
                                );
                            })}

                        {questions?.length === 0 && !loading && (
                            <TableRow>
                                <TableCell colSpan={5} className={styles.no_questions}>
                                    <p className={styles.no_questions_msg}>
                                        There are no questions assigned to this topic.
                                    </p>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {loading && (
                    <div className={styles.skeleton_container}>
                        {Array.from({ length: 6 }).map((_, i) => {
                            const key = `skeleton-${i}`;
                            return (
                                <Skeleton
                                    key={key}
                                    variant="rectangular"
                                    className={styles.skeleton}
                                />
                            );
                        })}
                    </div>
                )}
            </TableContainer>
        </Card>
    );
};

export default QuestionsTable;
