import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styles from 'styles/organisms/SelectActivityModal/ActivitySettings.scss';
import { Activity } from '../../../../types/Activity.js';
import TextInput from '../../atoms/TextInput.js';
import { trackEvent } from '../../../lib/analytics.js';
import { AnalyticsEvents } from '../../../../types/AnalyticsEvents.js';
import { useCreateStripeCheckoutSession } from '../../hooks/mutations/useCreateStripeCheckoutSession.js';
import { WritingScaffoldOptions } from '../../molecules/writingScaffolds/WritingScaffoldOptions.js';
import { WritingScaffolds } from '../../../../types/WritingScaffolds.js';
import { Question } from '../../../../types/Question.js';
import QuestionMarkTooltip from '../../atoms/QuestionMarkTooltip.js';

export interface ActivitySettingsProps {
    selectedActivity: Activity;
    isPremium: boolean;
    question: Question;
    allowCopyPaste: 'yes' | 'no';
    allowOpenComments: 'yes' | 'no';
    enableImmersiveReader: 'yes' | 'no';
    maxPairingsCount: number;
    onChangeCopyPasteSetting?: (allowCopyPaste: 'yes' | 'no') => void;
    onChangeOpenCommentsSetting?: (allowCopyPaste: 'yes' | 'no') => void;
    onChangeWritingScaffoldsSetting?: (options: WritingScaffolds) => void;
    onUpdateMaxPairingsCount: (maxPairings: number) => void;
    onChangeImmersiveReaderSetting: (allowImmersiveReader: 'yes' | 'no') => void;
}

export const ActivitySettings = ({
    selectedActivity,
    isPremium,
    question,
    allowCopyPaste,
    allowOpenComments,
    enableImmersiveReader,
    maxPairingsCount,
    onChangeCopyPasteSetting,
    onChangeOpenCommentsSetting,
    onChangeWritingScaffoldsSetting,
    onUpdateMaxPairingsCount,
    onChangeImmersiveReaderSetting,
}: ActivitySettingsProps) => {
    // Whether to show the writing scaffolds section, set to true by default because we want to
    // entice the user to upgrade to premium
    const [enableWritingScaffolds, setEnableWritingScaffolds] = useState<boolean>(false);
    const [selectedWritingScaffolds, setSelectedWritingScaffolds] =
        useState<WritingScaffolds>({
            'sentence-stems': false,
            outline: false,
            translate: false,
        });

    useEffect(() => {
        onChangeWritingScaffoldsSetting(selectedWritingScaffolds);
    }, [selectedWritingScaffolds]);

    const createStripeCheckoutSession = useCreateStripeCheckoutSession();

    const handleUpdateMaxPairingsCount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const maxPairings = Number(e.target.value);

        onUpdateMaxPairingsCount(maxPairings);
    };

    const handleChangeImmersiveReaderSetting = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const update = e.target.value as 'yes' | 'no';

        trackEvent(AnalyticsEvents.immersiveReaderSettingModified, {
            allowImmersiveReader: update,
        });

        onChangeImmersiveReaderSetting(update);
    };

    const handleChangeCopyPasteSetting = (e: React.ChangeEvent<HTMLInputElement>) => {
        const update = e.target.value as 'yes' | 'no';

        trackEvent(AnalyticsEvents.copyPasteSettingModified, {
            allowCopyPaste: update,
        });

        onChangeCopyPasteSetting(update);
    };

    const handleChangeOpenCommentsSetting = (e: React.ChangeEvent<HTMLInputElement>) => {
        const update = e.target.value as 'yes' | 'no';

        trackEvent(AnalyticsEvents.openCommentsSettingModified, {
            allowOpenComments: update,
        });

        onChangeOpenCommentsSetting(update);
    };

    const handleChangeWritingScaffoldsSetting = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const scaffoldName = e.target.name;
        const enabled = e.target.value === 'yes';

        const updatedWritingScaffoldOptions = {
            ...selectedWritingScaffolds,
        };

        updatedWritingScaffoldOptions[scaffoldName] = enabled;
        setSelectedWritingScaffolds(updatedWritingScaffoldOptions);
    };

    const handleShowHideWritingScaffoldsSection = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const enabled = e.target.value === 'Yes';
        setEnableWritingScaffolds(enabled);
        if (!enabled) {
            setSelectedWritingScaffolds(null);
        }
    };

    const handleClickPremiumButton = () => {
        trackEvent(AnalyticsEvents.openCommentsTryPremiumButtonClicked);

        createStripeCheckoutSession.mutate({
            planType: 'annual',
        });
    };

    return (
        <Card className={styles.settings_card_container}>
            <div className={styles.settings_container}>
                {selectedActivity?.slug === 'all-in' && (
                    <div
                        className={cn(
                            styles.settings_item_container,
                            styles.max_pairings_container,
                        )}
                    >
                        <div className={styles.form_label}>
                            <p>How many pairings should each student see?</p>
                            <p className={styles.subtext}>
                                Note: If there aren&apos;t enough students, each may see
                                fewer pairings.
                            </p>
                        </div>

                        <div className={styles.max_pairings_input}>
                            <TextInput
                                variant="outlined"
                                type="number"
                                defaultValue={maxPairingsCount}
                                inputProps={{
                                    min: 1,
                                }}
                                onChange={handleUpdateMaxPairingsCount}
                            />
                        </div>
                    </div>
                )}

                <FormControl className={cn([styles.format_radio_group_container])}>
                    <div className={styles.form_label}>
                        <p>Allow Copy Paste</p>
                        <p className={styles.subtext}>
                            Allow students to paste text into their response.
                        </p>
                    </div>
                    <RadioGroup
                        className={styles.format_radio_group}
                        value={allowCopyPaste}
                        aria-label="allow-copy-paste"
                        name="allow-copy-paste"
                        onChange={handleChangeCopyPasteSetting}
                    >
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    </RadioGroup>
                </FormControl>

                <div className={styles.premium_container}>
                    {!isPremium && (
                        <button
                            type="button"
                            aria-label="Upgrade to premium"
                            className={cn({
                                [styles.premium_overlay]: !isPremium,
                            })}
                            onClick={handleClickPremiumButton}
                        />
                    )}
                    <FormControl
                        className={cn([
                            styles.format_radio_group_container,
                            styles.settings_border,
                        ])}
                    >
                        <div className={styles.form_label}>
                            <p>Enable Immersive Reader</p>
                            <p className={styles.subtext}>
                                Enables text-to-speech, adjustable text, grammar,
                                translation,
                                <br />
                                and other tools for better accessibility.
                            </p>
                        </div>
                        <RadioGroup
                            className={styles.format_radio_group}
                            value={enableImmersiveReader}
                            aria-label="enable-immersive-reader"
                            name="enable-immersive-reader"
                            onChange={handleChangeImmersiveReaderSetting}
                            aria-disabled={!isPremium}
                        >
                            <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                                disabled={!isPremium}
                            />
                            <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                                disabled={!isPremium}
                            />
                        </RadioGroup>
                    </FormControl>
                    {selectedActivity?.slug !== 'battle-royale' &&
                        selectedActivity?.slug !== 'quick-write' && (
                            <FormControl
                                className={cn([styles.format_radio_group_container])}
                            >
                                <div className={styles.form_label}>
                                    <p>Allow Open Comments</p>
                                    <p className={styles.subtext}>
                                        Allow students to add open comments into their
                                        response.
                                    </p>
                                </div>
                                <RadioGroup
                                    className={styles.format_radio_group}
                                    value={allowOpenComments}
                                    aria-label="allow-open-comments"
                                    name="allow-open-comments"
                                    onChange={handleChangeOpenCommentsSetting}
                                    aria-disabled={!isPremium}
                                >
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio />}
                                        label="No"
                                        disabled={!isPremium}
                                    />
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio />}
                                        label="Yes"
                                        disabled={!isPremium}
                                    />
                                </RadioGroup>
                            </FormControl>
                        )}

                    <FormControl
                        className={cn([styles.writing_scaffols_section_container])}
                    >
                        <div className={styles.switch_form_container}>
                            <div className={styles.form_label}>
                                <p>
                                    Student Writing Scaffolds
                                    <QuestionMarkTooltip
                                        tooltipClassName={styles.tooltip}
                                        tooltipTitle="Create sentence stems and outlines during question creation before enabling them here"
                                    />
                                </p>
                                <p className={styles.subtext}>
                                    Allow students to access a variety of tool to help
                                    them while writing.
                                </p>
                            </div>

                            <RadioGroup
                                className={styles.format_radio_group}
                                value={isPremium && enableWritingScaffolds ? 'Yes' : 'No'}
                                aria-label="allow-copy-paste"
                                name="allow-copy-paste"
                                onChange={handleShowHideWritingScaffoldsSection}
                                disabled={!isPremium}
                            >
                                <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                />
                                <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                />
                            </RadioGroup>
                        </div>
                        {enableWritingScaffolds ? (
                            <WritingScaffoldOptions
                                isPremium={isPremium}
                                selectedWritingScaffolds={selectedWritingScaffolds}
                                question={question}
                                updateWritingScaffoldOptions={
                                    handleChangeWritingScaffoldsSetting
                                }
                            />
                        ) : null}
                    </FormControl>
                </div>
            </div>
        </Card>
    );
};
