import React from 'react';
import styles from 'styles/organisms/PasswordConfirmValidate.scss';
import * as pwValidator from '../_helpers/validatePassword.js';
import TextInput from '../atoms/TextInput.js';
import ValidatePasswordReqs from '../molecules/ValidatePasswordReqs.js';

interface PasswordConfirmValidateProps {
    password: string;
    onChangePassword: (password: string) => void;
    passwordFieldLabel?: string;
    confirmedPassword: string;
    onChangeConfirmedPassword: (password: string) => void;
    confirmPasswordFieldLabel?: string;
    containerClassName?: string;
}

const PasswordConfirmValidate = ({
    password,
    onChangePassword,
    passwordFieldLabel,
    confirmedPassword,
    onChangeConfirmedPassword,
    confirmPasswordFieldLabel,
    containerClassName,
}: PasswordConfirmValidateProps) => {
    const hasMinCharLength = pwValidator.hasMinCharLength(password);
    const hasUppercase = pwValidator.hasUppercase(password);
    const hasLowercase = pwValidator.hasLowercase(password);
    const hasNum = pwValidator.hasNum(password);
    const hasSpecialChar = pwValidator.hasSpecialChar(password);

    return (
        <span className={containerClassName}>
            <TextInput
                className={styles.input}
                type="password"
                label={passwordFieldLabel ?? 'Password'}
                value={password}
                required
                onChange={(e) => onChangePassword(e.target.value)}
                variant="outlined"
            />
            <ValidatePasswordReqs
                hasMinCharLength={hasMinCharLength}
                hasUppercase={hasUppercase}
                hasLowercase={hasLowercase}
                hasNum={hasNum}
                hasSpecialChar={hasSpecialChar}
            />
            <TextInput
                className={styles.input}
                type="password"
                label={confirmPasswordFieldLabel ?? 'Confirm Password'}
                value={confirmedPassword}
                required
                error={confirmedPassword !== password}
                helperText={
                    confirmedPassword === password ? null : "Password doesn't match"
                }
                onChange={(e) => onChangeConfirmedPassword(e.target.value)}
                variant="outlined"
            />
        </span>
    );
};

export default PasswordConfirmValidate;
