import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import styles from 'styles/organisms/PremiumSubscriptionModal.scss';
import { Dialog } from '../atoms/Dialog.js';

export const PremiumSubscriptionModal = ({
    show,
    onClose,
}: {
    show: boolean;
    onClose: () => void;
}) => {
    const dismissModal = () => null;

    const handleClose = () => {
        onClose();

        dismissModal();
    };

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            closeButtonClassName={styles.dialog_close_button}
            className={styles.dialog_background}
        >
            <DialogContent className={styles.dialog_content}>
                <h1 className={styles.h1}>Welcome to Premium!</h1>
                <h2 className={styles.h2}>
                    We&apos;re <span className={styles.italic}>SO</span> excited to
                    support your work.
                </h2>
                <div className={styles.iframe_container}>
                    <iframe
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/-ubEHIxKRXw?rel=0"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};
