import React, { useContext, useState } from 'react';
import styles from 'styles/organisms/SharedQuestionsList.scss';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useQueryClient } from 'react-query';
import Button from '../atoms/Button.js';
import { useSharedQuestions } from '../hooks/queries/useSharedQuestions.js';
import { RoomContext } from '../context/Room.js';
import { useSaveSharedQuestions } from '../hooks/mutations/useSaveSharedQuestions.js';
import { Question } from '../../../types/Question.js';
import Warning from '../icons/Warning.js';
import ActionPrompt from '../molecules/modals/ActionPrompt.js';
import { useCreateStripeCheckoutSession } from '../hooks/mutations/useCreateStripeCheckoutSession.js';
import { trackEvent } from '../../lib/analytics.js';
import { AnalyticsEvents } from '../../../types/AnalyticsEvents.js';
import { QueryKeys } from '../../../constants/queryKeys.js';
import { hasPremiumSubscription } from '../../../shared/subscriptionStatusHelpers.js';
import useDeleteSharedTopicTargetRoom from '../hooks/mutations/useDeleteSharedTopicTargetRoom.js';

export interface SharedQuestionsListProps {
    existingQuestions: Question[];
}

export const SharedQuestionsList = ({ existingQuestions }: SharedQuestionsListProps) => {
    const room = useContext(RoomContext);
    const queryClient = useQueryClient();

    const [alert, setAlert] = useState<{
        message?: string;
        severity: 'error' | 'success';
    }>(null);
    const [loadingTopicId, setLoadingTopicId] = useState('');
    const [showModal, setShowModal] = useState(false);

    const createStripeCheckoutSession = useCreateStripeCheckoutSession();

    const { data: sharedQuestionsData } = useSharedQuestions(room?.id);

    const saveSharedQuestions = useSaveSharedQuestions();
    const deleteSharedTopicTargetRoom = useDeleteSharedTopicTargetRoom();

    const isPremium = hasPremiumSubscription(room);

    const handleCheckout = () => {
        trackEvent(AnalyticsEvents.planFreeTrialButtonClicked, {
            planType: 'annual',
        });

        createStripeCheckoutSession.mutate({
            planType: 'annual',
        });
    };

    const sharedQuestions = sharedQuestionsData?.sharedQuestions ?? [];

    const sharedQuestionsMap = new Map();

    sharedQuestions.forEach((sharedQuestion) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { shared_topic_id, source_email, topic_name } = sharedQuestion;
        if (sharedQuestionsMap.has(shared_topic_id)) {
            sharedQuestionsMap.get(shared_topic_id).num_questions += 1;
        } else {
            sharedQuestionsMap.set(shared_topic_id, {
                id: sharedQuestion.id,
                source_email,
                topic_name,
                shared_topic_id,
                num_questions: 1,
            });
        }
    });

    const sharedQuestionsList = Array.from(sharedQuestionsMap.values());

    const saveQuestions = async (sharedTopicId: string) => {
        setLoadingTopicId(sharedTopicId);

        const sharedTopicName = sharedQuestionsMap.get(sharedTopicId).topic_name;

        const sharedQuestionsToAccept = sharedQuestions.filter(
            (sq) => sq.shared_topic_id === sharedTopicId,
        );

        try {
            await saveSharedQuestions.mutateAsync({
                sharedQuestions: sharedQuestionsToAccept,
                targetRoomId: room?.id,
                topicName: sharedQuestionsToAccept[0].topic_name,
                sharedTopicId,
            });

            queryClient.invalidateQueries(QueryKeys.sharedQuestionTopics);
            queryClient.invalidateQueries(QueryKeys.sharedQuestions);
            queryClient.invalidateQueries(QueryKeys.allRoomQuestions);
            queryClient.invalidateQueries(QueryKeys.allRoomTopics);
            sharedQuestionsMap.delete(sharedTopicId);
        } catch (error) {
            setAlert({
                message: `Error saving ${sharedTopicName} questions. Please try again.`,
                severity: 'error',
            });
        } finally {
            setLoadingTopicId('');
        }

        setAlert({
            message: `Successfully added ${sharedTopicName} questions!`,
            severity: 'success',
        });
    };

    const handleAccept = async (sharedTopicId: string) => {
        const sharedQuestionCount = sharedQuestionsMap.get(sharedTopicId).num_questions;
        if ((existingQuestions?.length ?? 0) + sharedQuestionCount > 5 && !isPremium) {
            setShowModal(true);
        } else {
            await saveQuestions(sharedTopicId);
        }
    };

    const handleDecline = async (sharedTopicId: string, targetRoomId: string) => {
        try {
            await deleteSharedTopicTargetRoom.mutateAsync({
                targetRoomId,
                sharedTopicId,
            });

            queryClient.invalidateQueries(QueryKeys.sharedQuestionTopics);
            queryClient.invalidateQueries(QueryKeys.sharedQuestions);
            queryClient.invalidateQueries(QueryKeys.allRoomQuestions);
            queryClient.invalidateQueries(QueryKeys.allRoomTopics);
        } catch (error) {
            setAlert({
                message: 'Error declining shared questions. Please try again.',
                severity: 'error',
            });
        }
    };

    return (
        <div>
            <ul className={styles.list}>
                {sharedQuestionsList.map((sq, index) => {
                    // Only show one banner at a time for shared questions notification
                    if (index > 0) {
                        return null;
                    }

                    return (
                        <li className={styles.list_item} key={sq.id}>
                            <p>{`${sq.source_email} has sent you ${sq.num_questions} ${
                                sq.topic_name
                            } question${sq.num_questions === 1 ? '' : 's'}`}</p>
                            <div>
                                <Button
                                    kind="text"
                                    className={styles.button}
                                    onClick={() =>
                                        handleDecline(sq.shared_topic_id, room?.id)
                                    }
                                >
                                    Decline
                                </Button>
                                <Button
                                    className={styles.primary_button}
                                    onClick={() => handleAccept(sq.shared_topic_id)}
                                    loading={sq.shared_topic_id === loadingTopicId}
                                >
                                    Accept
                                </Button>
                            </div>
                        </li>
                    );
                })}
            </ul>
            <ActionPrompt
                title="Unable to Accept"
                content={
                    <div className={styles.modal_content}>
                        Accepting this topic folder puts your account over the 5 question
                        limit of the Basics plan. Please upgrade to a{' '}
                        <a
                            href="https://myshortanswer.com/see-plans/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Premium Plan
                        </a>{' '}
                        to accept.
                    </div>
                }
                headerIcon={Warning}
                headerIconClassName={styles.warning_icon}
                open={showModal}
                onClose={() => setShowModal(false)}
                onPrimaryAction={handleCheckout}
                primaryActionText="Upgrade to Premium"
                onSecondaryAction={() => setShowModal(false)}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={!!alert?.message}
                autoHideDuration={7000}
                onClose={() => setAlert(null)}
            >
                <Alert severity={alert?.severity} onClose={() => setAlert(null)}>
                    {alert?.message}
                </Alert>
            </Snackbar>
        </div>
    );
};
