import React, { MouseEvent, useRef, useEffect } from 'react';
import Collapse from '@mui/material/Collapse';
import Card from '@mui/material/Card';
import styles from 'styles/organisms/MathKeyboard.scss';
import { mathSymbols } from '../../../constants/MathSymbolUnicodes.js';

export interface MathKeyboardProps {
    expanded: boolean;
    onMathKeyClick: (e: MouseEvent<HTMLButtonElement>, unicode: string) => void;
    onCollapse: () => void;
    className?: string;
    excludeClickRefs?: React.RefObject<HTMLElement>[];
}

const unicodeToString = (unicode) => {
    if (Array.isArray(unicode)) {
        return unicode
            .map((code) => String.fromCharCode(parseInt(code.substring(2), 16)))
            .join(', ');
    }

    return String.fromCharCode(parseInt(unicode.substring(2), 16));
};

const MathKeyboard = ({
    className,
    expanded,
    onMathKeyClick,
    onCollapse,
    excludeClickRefs,
}: MathKeyboardProps) => {
    const cardRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                expanded &&
                cardRef.current &&
                !cardRef.current.contains(event.target) &&
                !excludeClickRefs.some(
                    (ref) => ref.current && ref.current.contains(event.target),
                )
            ) {
                onCollapse();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [expanded]);

    return (
        <div className={className}>
            <Collapse in={expanded}>
                <Card className={styles.card} tabIndex={0} ref={cardRef}>
                    {mathSymbols.map((symbol) => {
                        const unicodeChar = unicodeToString(symbol.unicode);
                        return (
                            <button
                                key={symbol.label}
                                className={styles.math_key}
                                aria-label={symbol.label}
                                onMouseDown={(e) => onMathKeyClick(e, unicodeChar)}
                                type="button"
                                title={symbol.displayName}
                            >
                                <p>{unicodeChar}</p>
                            </button>
                        );
                    })}
                </Card>
            </Collapse>
        </div>
    );
};

export default MathKeyboard;
