import React, { MouseEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';
import DialogContent from '@mui/material/DialogContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Chip from '@mui/material/Chip';
import RadioGroup from '@mui/material/RadioGroup';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { customAlphabet } from 'nanoid';
import cn from 'classnames';
import styles from 'styles/organisms/SelectActivityModal/SelectActivityModal.scss';
import RightArrowIcon from '../../icons/RightArrowIcon.js';
import PairIt from '../../icons/svgs/ActivityCardIcons/PairIt.svg';
import AllIn from '../../icons/svgs/ActivityCardIcons/AllIn.svg';
import BattleRoyale from '../../icons/svgs/ActivityCardIcons/BattleRoyale.svg';
import PartnerUp from '../../icons/svgs/ActivityCardIcons/PartnerUp.svg';
import QuickWrite from '../../icons/svgs/ActivityCardIcons/QuickWrite.svg';
import BackArrow from '../../icons/BackArrow.js';
import RocketShip from '../../icons/RocketShip.js';
import Button from '../../atoms/Button.js';
import { Dialog } from '../../atoms/Dialog.js';
import ActivityCardRadio from '../../molecules/ActivityCardRadio.js';
import { ActivitySettings } from './ActivitySettings.js';
import { RoomContext } from '../../context/Room.js';
import { SocketContext } from '../../context/Socket.js';
import { useAddSession } from '../../hooks/mutations/useAddSession.js';
import { useAllActivities } from '../../hooks/queries/useAllActivities.js';
import { trackEvent } from '../../../lib/analytics.js';
import { hasPremiumSubscription } from '../../../../shared/subscriptionStatusHelpers.js';
import { SESSION } from '../../../../constants/channels.js';
import { Activity } from '../../../../types/Activity.js';
import { Question } from '../../../../types/Question.js';
import { WritingScaffolds } from '../../../../types/WritingScaffolds.js';
import { SocketResponse } from '../../../../types/Socket.js';
import { AnalyticsEvents } from '../../../../types/AnalyticsEvents.js';
import { FeatureFlagContext } from '../../context/FeatureFlag.js';

// TODO: This is an empty comming soon place holder,
// remove when we add a second & third cooperative activity
const commingSoonActivity = {
    id: '',
    description: '',
    slug: 'coming-soon',
    display_name: '',
    coming_soon: true,
    type: 'cooperative',
    created_at: '',
    updated_at: '',
};

// We map through them to give each a unique activity slug value to use as a key
const comingSoonActivities = [commingSoonActivity, commingSoonActivity].map(
    (activity, index) => {
        return {
            ...activity,
            slug: `coming-soon-${index}`,
        };
    },
);

export interface SelectActivityModalProps {
    show: boolean;
    question: Question;
    onClose: () => void;
}

export const SelectActivityModal = ({
    show,
    question,
    onClose,
}: SelectActivityModalProps) => {
    const navigate = useNavigate();
    const room = useContext(RoomContext);
    const featureFlags = useContext(FeatureFlagContext);
    const socket = useContext(SocketContext);

    const quickWriteFeatureFlag = featureFlags.find(
        (flag) => flag.feature === 'quick-write',
    );
    const showFeature =
        (quickWriteFeatureFlag?.is_active &&
            quickWriteFeatureFlag?.members?.includes(room?.id)) ||
        !quickWriteFeatureFlag?.is_active;

    const [sortedActivities, setSortedActivities] =
        useState<Record<string, Activity[]>>();
    const [selectedActivity, setSelectedActivity] = useState<Activity>();
    const [allowCopyPaste, setAllowCopyPaste] = useState<'yes' | 'no'>('yes');
    const [allowOpenComments, setAllowOpenComments] = useState<'yes' | 'no'>('no');
    const [enableImmersiveReader, setEnableImmersiveReader] = useState<'yes' | 'no'>(
        'no',
    );
    const [maxPairingsCount, setMaxPairingsCount] = useState(3);
    const [writingScaffoldsList, setWritingScaffoldsList] =
        useState<WritingScaffolds>(null);
    const [error, setError] = useState<string | null>(null);
    const [state, setState] = useState<'showActivities' | 'showSettings'>(
        'showActivities',
    );
    const [activityType, setActivityType] = useState<'competitive' | 'cooperative'>(
        'competitive',
    );

    const { data, isLoading } = useAllActivities();
    const isPremium = hasPremiumSubscription(room);
    const addSessionMutation = useAddSession();

    const { activities } = data ?? {};

    const sortActivities = useCallback((activityArray) => {
        return activityArray?.sort((a, b) => {
            if (a.coming_soon !== b.coming_soon) {
                return a.coming_soon ? 1 : -1;
            }
            if (a.updated_at !== b.updated_at) {
                return a.updated_at > b.updated_at ? 1 : -1;
            }
            return a.slug.localeCompare(b.slug);
        });
    }, []);

    useEffect(() => {
        if (activities) {
            const activityMap = activities.reduce((acc, activity) => {
                acc[activity.type] = acc[activity.type] || [];
                acc[activity.type].push(activity);
                return acc;
            }, {} as Record<string, Activity[]>);

            setSortedActivities({
                competitive: sortActivities(activityMap.competitive || []),
                cooperative: [
                    ...sortActivities(activityMap.cooperative || []),
                    ...comingSoonActivities,
                ],
            });
        }
    }, [activities]);

    useEffect(() => {
        setSelectedActivity(sortedActivities?.[activityType][0]);
    }, [sortedActivities, activityType]);

    useGSAP(
        () => {
            gsap.timeline().set('.activity', { opacity: 0 }).to('.activity', {
                opacity: 1,
                duration: 0.75,
                stagger: 0.2,
                ease: 'power2.inOut',
                delay: 0.2,
            });
        },
        { dependencies: [activityType, state], revertOnUpdate: true },
    );

    useGSAP(
        () => {
            gsap.from('.title', {
                y: -50,
                ease: 'power2.inOut',
                delay: 0,
                duration: 0.75,
            });
            gsap.from('.caption', {
                opacity: 0,
                delay: 0.1,
                ease: 'power2.out',
                duration: 1,
            });
            gsap.from('.backBtn', {
                opacity: 0,
                delay: 0.2,
                ease: 'power2.out',
                duration: 0.75,
            });
            if (state === 'showSettings') {
                gsap.from('.settings_container', {
                    opacity: 0,
                    duration: 1,
                    ease: 'power2.out',
                    delay: 0.2,
                });
            }
        },
        { dependencies: [state], revertOnUpdate: true },
    );

    const handleChangeActivity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newActivity = sortedActivities[activityType].find(
            (a) => a.id === e.target.value,
        );
        setSelectedActivity(newActivity);
    };

    const handleContinue = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const nanoid = customAlphabet('ABCDEFGHJKLMNPQRSTUVWXYZ123456789', 5);
        const joinCode = nanoid();

        // Add the session when the teacher starts the activity. By default, a new session is active
        addSessionMutation.mutate(
            {
                questionId: question?.id,
                joinCode,
                activitySlug: selectedActivity?.slug,
                allowCopyPaste: allowCopyPaste === 'yes',
                allowOpenComments: allowOpenComments === 'yes',
                enableImmersiveReader: enableImmersiveReader === 'yes',
                writingScaffolds: writingScaffoldsList,
                allInMaxPairingsCount: maxPairingsCount,
            },
            {
                onSuccess: (res) => {
                    trackEvent(AnalyticsEvents.newActivitySessionStarted, res.session);

                    socket.emit(
                        SESSION.JOIN,
                        { joinCode: res.session.join_code },
                        (socketRes: SocketResponse) => {
                            if (socketRes.success) {
                                trackEvent(
                                    AnalyticsEvents.teacherJoinedSession,
                                    res.session,
                                );

                                navigate(
                                    `/room/${joinCode}/${selectedActivity?.slug}/count-responses`,
                                    { state: { showJoinCode: true } },
                                );
                            } else {
                                // TODO: Need to show in the UI if there's an error joining the session
                                captureException(socketRes.error);
                                setError('Error starting activity. Please try again.');
                            }
                        },
                    );
                },
                onError: (err) => {
                    captureException(err);
                    setError('Error starting activity. Please try again.');
                },
            },
        );
    };

    const handleClickNext = () => {
        setState('showSettings');
    };

    const handleGoBackToActivitySelection = () => {
        setState('showActivities');
    };

    const handleCloseModal = () => {
        onClose();
        setState('showActivities');
    };

    const stateMachine = {
        showActivities: {
            iconButtonStyles: styles.hide,
            h1Styles: null,
            title: 'Select an activity!',
            subtitle: showFeature ? (
                <Tabs
                    value={activityType}
                    onChange={(_, value) => setActivityType(value)}
                    className={styles.tabs}
                >
                    <Tab label="Competitive" value="competitive" className={styles.tab} />
                    <Tab
                        label={
                            <>
                                Cooperative <Chip label="New!" />
                            </>
                        }
                        value="cooperative"
                        className={styles.tab}
                    />
                </Tabs>
            ) : null,
            buttonLabel: 'Next',
            buttonProps: {
                endIcon: <RightArrowIcon className={styles.right_arrow_icon} />,
                onClick: handleClickNext,
            },
            content: (
                <div>
                    <RadioGroup
                        onChange={handleChangeActivity}
                        name="activity-types"
                        value={selectedActivity?.id}
                    >
                        <span className={styles.activity_radio_card_container}>
                            {sortedActivities?.[activityType].map((activity) => {
                                let HalfCardIconSvg = PairIt;

                                // TODO: Eventually we will store images in an S3 bucket and pull them from there
                                switch (activity.slug) {
                                    case 'all-in':
                                        HalfCardIconSvg = AllIn;
                                        break;
                                    case 'battle-royale':
                                        HalfCardIconSvg = BattleRoyale;
                                        break;
                                    case 'partner-up':
                                        HalfCardIconSvg = PartnerUp;
                                        break;
                                    case 'quick-write':
                                        HalfCardIconSvg = QuickWrite;
                                        break;
                                    default:
                                        HalfCardIconSvg = PairIt;
                                }

                                return (
                                    <ActivityCardRadio
                                        key={activity.slug}
                                        cardClassName={cn([
                                            'activity',
                                            styles.activity_card_radio,
                                        ])}
                                        activity={activity}
                                        selectedActivity={selectedActivity}
                                        halfCardIconSvg={HalfCardIconSvg}
                                        loading={isLoading}
                                        // isPremium={isPremium}
                                    />
                                );
                            })}
                        </span>
                    </RadioGroup>
                </div>
            ),
        },
        showSettings: {
            iconButtonStyles: null,
            h1Styles: styles.activity_settings_h1,
            title: `${selectedActivity?.display_name} Settings`,
            subtitle: selectedActivity?.description,
            buttonLabel: 'Launch',
            buttonProps: {
                id: 'select-activity-modal-launch-button',
                startIcon: <RocketShip />,
                onClick: handleContinue,
                loading: addSessionMutation.isLoading,
            },
            content: (
                <div className="settings_container">
                    <ActivitySettings
                        selectedActivity={selectedActivity}
                        isPremium={isPremium}
                        question={question}
                        allowCopyPaste={allowCopyPaste}
                        allowOpenComments={allowOpenComments}
                        enableImmersiveReader={enableImmersiveReader}
                        maxPairingsCount={maxPairingsCount}
                        onChangeCopyPasteSetting={(update) => setAllowCopyPaste(update)}
                        onChangeOpenCommentsSetting={(update) =>
                            setAllowOpenComments(update)
                        }
                        onChangeWritingScaffoldsSetting={(update) =>
                            setWritingScaffoldsList(update)
                        }
                        onUpdateMaxPairingsCount={(count) => setMaxPairingsCount(count)}
                        onChangeImmersiveReaderSetting={(update) =>
                            setEnableImmersiveReader(update)
                        }
                    />
                </div>
            ),
        },
    };

    return (
        <>
            <Dialog
                open={show}
                onClose={handleCloseModal}
                closeButtonClassName={styles.dialog_close_button}
                id="select-activity-modal"
            >
                <DialogContent className={styles.dialog_content}>
                    <form>
                        <div className={styles.form_header_container}>
                            <Button
                                className={cn(
                                    'backBtn',
                                    styles.back_button,
                                    stateMachine[state].iconButtonStyles,
                                )}
                                kind="text"
                                onClick={handleGoBackToActivitySelection}
                            >
                                <BackArrow className={styles.back_arrow_icon} />
                                BACK
                            </Button>
                            <div className={styles.header_container}>
                                <div className={styles.title_container}>
                                    <h1
                                        className={cn(
                                            'title',
                                            styles.h1,
                                            stateMachine[state].h1Styles,
                                        )}
                                    >
                                        {stateMachine[state].title}
                                    </h1>
                                    <p className={cn(['caption', styles.caption])}>
                                        {stateMachine[state].subtitle}
                                    </p>
                                </div>
                                <div className={styles.start_button_container}>
                                    <Button
                                        kind="primary"
                                        className={styles.button}
                                        type="button"
                                        {...stateMachine[state].buttonProps}
                                    >
                                        {stateMachine[state].buttonLabel}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {activityType === 'cooperative' && state !== 'showSettings' && (
                            <div className={cn(['activity', styles.quick_write_note])}>
                                <strong>*NOTE:</strong> Quick Write is temporarily free
                                during Public Beta. For continued access,{' '}
                                <a
                                    rel="noreferrer"
                                    href="https://myshortanswer.com/quick-write-public-beta-promotion/"
                                    target="_blank"
                                >
                                    explore our options.
                                </a>
                            </div>
                        )}
                        <div className="content">{stateMachine[state].content}</div>
                    </form>
                </DialogContent>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={!!error}
            >
                <Alert severity="error" onClose={() => setError(null)}>
                    {error}
                </Alert>
            </Snackbar>
        </>
    );
};
